import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { FunctionComponent, ReactNode, useState } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

const FormControlPassword: FunctionComponent<{
  label: ReactNode;
  name: string;
  disabled?: boolean;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
}> = ({ label, name, disabled, register, errors }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((c) => !c);

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor='outlined-adornment-password-login'>
        {label}
      </InputLabel>
      <OutlinedInput
        inputProps={register(name)}
        error={!!errors[name]}
        type={showPassword ? 'text' : 'password'}
        label={label}
        disabled={disabled}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              edge='end'
              size='large'
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText error>
        {errors?.[name]?.message?.toString()}
      </FormHelperText>
    </FormControl>
  );
};

export default FormControlPassword;
