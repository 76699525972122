import api from 'config/api';
import { APIError } from 'error/APIError';
import { ApiResponse, AuthenticationPayload } from '@mrp-system/common';

export const loginUser = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const { data, headers } = await api.post<ApiResponse<AuthenticationPayload>>(
    '/user/login',
    {
      email,
      password,
    }
  );

  if (data.status === 'SUCCESS' || data.status === 'WARNING') {
    return data;
  } else {
    throw new APIError(data.message, data, headers);
  }
};
