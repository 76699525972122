import { ReactNode } from 'react';
import { toast, ToastPosition } from 'react-toastify';

const defaultProps = {
  position: 'top-right' as ToastPosition,
  autoClose: 7000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const toastReporter = (
  message: ReactNode,
  options?: { autoClose: number | false }
) => {
  toast.error(message, { ...defaultProps, ...options });
};

export const toastError = toastReporter;

export const toastSuccess = (
  message: ReactNode,
  options?: { autoClose: number | false }
) => {
  toast.success(message, { ...defaultProps, ...options });
};

export const toastWarning = (
  message: ReactNode,
  options?: { autoClose: number | false }
) => {
  // toast.warning(message, {...defaultProps,...options});
  toast.warn(message, { ...defaultProps, ...options });
};
