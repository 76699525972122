"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatName = void 0;
const formatName = (fullName) => {
    const exceptions = [
        'de',
        'e',
        'da',
        'das',
        'o',
        'os',
        'do',
        'dos',
        'na',
        'nas',
        'no',
        'nos',
    ];
    return fullName
        .trim()
        .replace(/\s+/gm, ' ')
        .toLocaleLowerCase()
        .split(' ')
        .map((word) => exceptions.includes(word)
        ? word
        : word.charAt(0).toLocaleUpperCase() + word.slice(1))
        .join(' ');
};
exports.formatName = formatName;
