/**
 * Color intention that you want to used in your theme
 * @param {JsonObject} theme Theme customization object
 */

import * as colors from '@mui/material/colors';

const primaryColor = {
  light: '#ffe2c5',
  main: '#d39b80',
  dark: '#57200e',
  get 50() {
    return this.light;
  },
  100: '#f3c0a5',
  get 200() {
    return this.main;
  },
  300: '#b17759',
  400: '#995d3d',
  500: '#804321',
  600: '#753a1c',
  700: '#652e14',
  800: '#57200e',
  900: '#471200',
};

const secondaryColor = {
  light: '#e26656',
  main: '#c54b38',
  dark: '#a73e29',
  50: '#fdedee',
  100: '#fad1d3',
  200: '#e7a19d',
  300: '#da7f78',
  get 400() {
    return this.light;
  },
  500: '#e55c3f',
  600: '#d7543e',
  get 700() {
    return this.main;
  },
  800: '#b84532',
  get 900() {
    return this.dark;
  },
};

export default function themePalette(theme) {
  return {
    ...colors,
    mode: theme?.customization?.navType,
    common: {
      black: theme.colors?.darkPaper,
    },

    primary: primaryColor,
    secondary: secondaryColor,

    error: {
      light: theme.colors?.errorLight,
      main: theme.colors?.errorMain,
      dark: theme.colors?.errorDark,
    },
    warning: {
      light: theme.colors?.warningLight,
      main: theme.colors?.warningMain,
      dark: theme.colors?.warningDark,
    },
    success: {
      light: theme.colors?.successLight,
      200: theme.colors?.success200,
      main: theme.colors?.successMain,
      dark: theme.colors?.successDark,
    },
    dark: {
      light: theme.colors?.darkTextPrimary,
      main: theme.colors?.darkLevel1,
      dark: theme.colors?.darkLevel2,
      800: theme.colors?.darkBackground,
      900: theme.colors?.darkPaper,
    },
    text: {
      primary: theme.darkTextPrimary,
      secondary: theme.darkTextSecondary,
      dark: theme.textDark,
      hint: theme.colors?.grey100,
    },
    background: {
      paper: theme.paper,
      default: theme.backgroundDefault,
    },
    mainButtons: {
      color: primaryColor.main,
      backgroundColor: primaryColor[500],
      '&:hover': {
        color: primaryColor.main,
        backgroundColor: `${primaryColor.light} !important`,
      },
    },
    secondaryButtons: {
      color: secondaryColor[100],
      backgroundColor: secondaryColor[500],
      '&:hover': {
        color: secondaryColor.main,
        backgroundColor: `${secondaryColor[200]} !important`,
      },
    },
  };
}
