"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatterProductTypes = formatterProductTypes;
exports.formatterRecipeTypes = formatterRecipeTypes;
exports.formatterMeasurementUnits = formatterMeasurementUnits;
exports.getMeasurementUnit = getMeasurementUnit;
exports.formatterTransactionTypes = formatterTransactionTypes;
exports.formatterTransactionStatus = formatterTransactionStatus;
const Product_1 = require("../models/Product");
const Transaction_1 = require("../models/Transaction");
function formatterProductTypes(value) {
    const values = {
        [Product_1.ProductType.PRODUCED]: {
            badgeClass: '',
            color: '',
            label: 'Produzido',
            value: Product_1.ProductType.PRODUCED,
        },
        [Product_1.ProductType.INPUT]: {
            badgeClass: '',
            color: '',
            label: 'Insumo',
            value: Product_1.ProductType.INPUT,
        },
    };
    return (value ? values[value] : Object.values(values));
}
function formatterRecipeTypes(value) {
    const values = {
        [Product_1.RecipeType.SIMPLE]: {
            badgeClass: '',
            color: '',
            label: 'Simples',
            value: Product_1.RecipeType.SIMPLE,
        },
        [Product_1.RecipeType.COMPOSED]: {
            badgeClass: '',
            color: '',
            label: 'Composta',
            value: Product_1.RecipeType.COMPOSED,
        },
    };
    return (value ? values[value] : Object.values(values));
}
function formatterMeasurementUnits(value) {
    const values = {
        [Product_1.MeasurementUnits.MILLILITER]: {
            badgeClass: '',
            color: '',
            label: 'Mililitro (ml)',
            value: Product_1.MeasurementUnits.MILLILITER,
        },
        [Product_1.MeasurementUnits.GRAM]: {
            badgeClass: '',
            color: '',
            label: 'Grama (g)',
            value: Product_1.MeasurementUnits.GRAM,
        },
        [Product_1.MeasurementUnits.UNIT]: {
            badgeClass: '',
            color: '',
            label: 'Unidade (un)',
            value: Product_1.MeasurementUnits.UNIT,
        },
    };
    return (value ? values[value] : Object.values(values));
}
function getMeasurementUnit(unit) {
    const units = {
        MILLILITER: 'ml',
        GRAM: 'g',
        UNIT: ' un',
    };
    return units[unit];
}
function formatterTransactionTypes(value) {
    const values = {
        [Transaction_1.TransactionType.INPUT]: {
            badgeClass: '',
            color: '',
            label: 'Entrada',
            value: Transaction_1.TransactionType.INPUT,
        },
        [Transaction_1.TransactionType.OUTPUT]: {
            badgeClass: '',
            color: '',
            label: 'Saída',
            value: Transaction_1.TransactionType.OUTPUT,
        },
    };
    return (value ? values[value] : Object.values(values));
}
function formatterTransactionStatus(value) {
    const values = {
        [Transaction_1.TransactionStatus.PENDING]: {
            badgeClass: '',
            color: 'orange.50',
            label: 'Pendente',
            value: Transaction_1.TransactionStatus.PENDING,
        },
        [Transaction_1.TransactionStatus.CONFIRMED]: {
            badgeClass: '',
            color: 'success.light',
            label: 'Confirmado',
            value: Transaction_1.TransactionStatus.CONFIRMED,
        },
        [Transaction_1.TransactionStatus.FINISHED]: {
            badgeClass: '',
            color: 'grey.200',
            label: 'Finalizado',
            value: Transaction_1.TransactionStatus.FINISHED,
        },
    };
    return (value ? values[value] : Object.values(values));
}
