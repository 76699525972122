import { CircularProgress } from '@mui/material';

const LabelLoading = () => {
  return (
    <>
      <CircularProgress size={15} sx={{ marginRight: 1 }} /> Aguarde...
    </>
  );
};

export default LabelLoading;
