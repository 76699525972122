import { useContext } from 'react';
import { AuthContext } from 'context/AuthContext';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, Typography } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const { user } = useContext(AuthContext);

  return (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
      <Logo maxHeight={60} />
      <Typography variant='h4' mt={1} ml={1}>
        {user.company.name}
      </Typography>
    </ButtonBase>
  );
};

export default LogoSection;
