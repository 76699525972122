import { PropsWithChildren } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

const ErrorCatcher = ({ children }: PropsWithChildren) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={(...r) => console.log('r-onReset :>> ', r)}
      onError={(...r) => console.log('r-onError :>> ', r)}
    >
      {children}
    </ErrorBoundary>
  );
};

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <div>
      <h4>Algo deu errado.</h4>
      <span>Informação de depuração </span>
      <pre>{error.message}</pre>
      <pre>{error.stack}</pre>
      <button onClick={resetErrorBoundary}>reset</button>
    </div>
  );
};

export default ErrorCatcher;
