import axios, { AxiosError } from 'axios';
import { toastReporter } from 'utils/toast-utils';
import { APIError } from './APIError';

export const defaultErrorHandler = (
  error: APIError | AxiosError | Error | unknown,
  messageReporter: (data: any) => void = toastReporter
) => {
  if (process.env.NODE_ENV === 'development') console.log('error :>> ', error);

  if (error instanceof APIError) {
    //TODO: ZIP Error
    messageReporter(error.message);
  } else if (
    (axios.isAxiosError(error) || error instanceof AxiosError) &&
    (error as any).response?.data?.message
  ) {
    messageReporter((error as any).response?.data?.message);
  } else {
    messageReporter(
      'Ocorreu um erro inesperado. Por favor, recarregue a página e tente novamente.'
    );
  }
};
