// assets
import { UserPermissions } from '@mrp-system/common';
import { IconUser } from '@tabler/icons';
import { SidebarMenu } from 'utils/types';

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages: SidebarMenu = {
  id: 'configuration',
  title: 'Configurações',
  type: 'group',
  children: [
    {
      id: 'usuarios',
      title: 'Usuários',
      type: 'item',
      url: '/usuarios',
      icon: IconUser,
      breadcrumbs: true,
      permissionsRequired: [UserPermissions.GET_ALL_USERS],
    },
  ],
};

export default pages;
