// material-ui
import { useContext } from 'react';
import { AuthContext } from 'context/AuthContext';
import { Box, SxProps, Theme } from '@mui/material';
import LogoImage from 'assets/images/logo.svg';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = (props: SxProps<Theme>) => {
  const { user } = useContext(AuthContext);

  return (
    <Box
      component='img'
      sx={{
        maxWidth: '80%',
        maxHeight: '250px',
        mx: 'auto',
        display: 'flex',
        ...props,
      }}
      alt={`Logomarca ${user ? user.company.name : ''}`}
      src={LogoImage}
    />
  );
};

export default Logo;
