import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router-dom';

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import('views/dashboard/Default'))
);

const ProductsIndex = Loadable(
  lazy(() => import('views/manager/products/Index'))
);
const ProductsEdit = Loadable(
  lazy(() => import('views/manager/products/Edit'))
);
const InventoryMovementIndex = Loadable(
  lazy(() => import('views/manager/inventory-movement/Index'))
);
const InventoryMovementEdit = Loadable(
  lazy(() => import('views/manager/inventory-movement/Edit'))
);
// TODO:
const RegisterProduction = Loadable(
  lazy(() => import('views/manager/inventory-movement/RegisterProduction'))
);
const FinancesIndex = Loadable(
  lazy(() => import('views/manager/finances/Index'))
);
const FinancesEdit = Loadable(
  lazy(() => import('views/manager/finances/Edit'))
);
const FinancesSaleEdit = Loadable(
  lazy(() => import('views/manager/finances/Sale'))
);
const FinancesSupplyEdit = Loadable(
  lazy(() => import('views/manager/finances/Supply'))
);
const StockIndex = Loadable(lazy(() => import('views/manager/stock/Index')));
const FinancesNfcEIndex = Loadable(
  lazy(() => import('views/manager/finances/nfc-e/Index'))
);

const UsersIndex = Loadable(
  lazy(() => import('views/configuration/users/Index'))
);
const UsersEdit = Loadable(
  lazy(() => import('views/configuration/users/Edit'))
);

// utilities routing
const UtilsTypography = Loadable(
  lazy(() => import('views/utilities/Typography'))
);
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(
  lazy(() => import('views/utilities/MaterialIcons'))
);
const UtilsTablerIcons = Loadable(
  lazy(() => import('views/utilities/TablerIcons'))
);

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = (isAuthenticated) => ({
  path: '/',
  element: isAuthenticated ? (
    <MainLayout />
  ) : (
    <Navigate to={`/login?r=${location.pathname + location.search}`} />
  ),
  children: [
    {
      path: '',
      element: <Navigate to={`/dashboard`} />,
    },
    {
      path: 'dashboard',
      element: <DashboardDefault />,
    },

    {
      path: 'produtos',
      children: [
        { path: '', element: <ProductsIndex /> },
        { path: 'produto/criar', element: <ProductsEdit /> },
        { path: 'produto/:id/editar', element: <ProductsEdit /> },
      ],
    },

    {
      path: 'estoque',
      children: [{ path: '', element: <StockIndex /> }],
    },

    {
      path: 'movimentacao-estoque',
      children: [
        { path: '', element: <InventoryMovementIndex /> },
        // TODO:
        // {
        //   path: 'movimentacao/criar',
        //   element: <InventoryMovementEdit />,
        // },
        {
          path: 'movimentacao/:id/editar',
          element: <InventoryMovementEdit />,
        },
        // TODO:
        // {
        //   path: 'registrar-producao',
        //   element: <RegisterProduction />,
        // },
      ],
    },

    {
      path: 'financeiro',
      children: [
        {
          path: '',
          element: <FinancesIndex />,
        },
        // TODO:
        //   path: 'transacao/criar',
        //   element: <FinancesEdit />,
        // },
        // {
        //   path: 'transacao/criar',
        //   element: <FinancesEdit />,
        // },
        {
          path: 'transacao/:id/editar',
          element: <FinancesEdit />,
        },
        {
          path: 'venda/criar',
          element: <FinancesSaleEdit />,
        },
        {
          path: 'venda/:id/editar',
          element: <FinancesSaleEdit />,
        },
        {
          path: 'abastecimento/criar',
          element: <FinancesSupplyEdit />,
        },
        {
          path: 'abastecimento/:id/editar',
          element: <FinancesSupplyEdit />,
        },
        {
          path: 'nfc-e-compras',
          element: <FinancesNfcEIndex />,
        },
        {
          path: 'nfc-e-compras/:idNfce/criar',
          element: <FinancesSupplyEdit nfce />,
        },
      ],
    },

    {
      path: 'usuarios',
      children: [
        {
          path: '',
          element: <UsersIndex />,
        },
        {
          path: 'usuario/criar',
          element: <UsersEdit />,
        },
        {
          path: 'usuario/:id/editar',
          element: <UsersEdit />,
        },
      ],
    },

    {
      path: 'utils',
      children: [
        {
          path: 'util-typography',
          element: <UtilsTypography />,
        },
      ],
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-color',
          element: <UtilsColor />,
        },
      ],
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-shadow',
          element: <UtilsShadow />,
        },
      ],
    },
    {
      path: 'icons',
      children: [
        {
          path: 'tabler-icons',
          element: <UtilsTablerIcons />,
        },
      ],
    },
    {
      path: 'icons',
      children: [
        {
          path: 'material-icons',
          element: <UtilsMaterialIcons />,
        },
      ],
    },
    {
      path: 'sample-page',
      element: <SamplePage />,
    },
    {
      path: 'nao-autorizado',
      element: (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ lineHeight: 1, fontSize: 100 }}>403</span>
          <span style={{ lineHeight: 1, fontSize: 30 }}>
            Sem permissão para acessar esse recurso
          </span>
        </div>
      ),
    },
    {
      path: '*',
      element: (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ lineHeight: 1, fontSize: 100 }}>404</span>
          <span style={{ lineHeight: 1, fontSize: 30 }}>
            Página não encontrada
          </span>
        </div>
      ),
    },
  ],
});

export default MainRoutes;
