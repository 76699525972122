"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeMyPasswordValidator = exports.changeMyPasswordSchema = void 0;
const yup_custom_1 = require("../yup-custom");
exports.changeMyPasswordSchema = yup_custom_1.yup
    .object()
    .shape({
    currentPassword: yup_custom_1.yup.string().required(),
    newPassword: yup_custom_1.yup.string().required().min(8),
});
const changeMyPasswordValidator = async (body) => {
    return await exports.changeMyPasswordSchema.validate(body, { stripUnknown: true });
};
exports.changeMyPasswordValidator = changeMyPasswordValidator;
