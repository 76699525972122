"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.yup = void 0;
const yup = require("yup");
exports.yup = yup;
const yup_locale_pt_1 = require("yup-locale-pt");
// import '../global';
yup.setLocale(yup_locale_pt_1.ptForm);
yup.addMethod(yup.string, 'cpf', function () {
    return yup.string().matches(/^[0-9]{11}$|^$/);
});
yup.addMethod(yup.string, 'phone', function () {
    return yup.string().matches(/^[0-9]{10,11}$|^$/);
});
yup.addMethod(yup.string, 'cep', function () {
    return yup.string().length(8).matches(/^\d+$/);
});
yup.addMethod(yup.string, 'rg', function () {
    return yup.string().matches(/^[A-Za-z0-9]{1,14}$|^$/);
});
// yup.addMethod<yup.MixedSchema>(yup.mixed, 'enum', function (this, enumValue) {
yup.addMethod(yup.mixed, 'enum', function (enumValue) {
    return yup.mixed().oneOf(Object.values(enumValue));
});
