"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPermissionsLabels = exports.UserPermissions = void 0;
var UserPermissions;
(function (UserPermissions) {
    UserPermissions["GET_ALL_COMPANIES"] = "GET_ALL_COMPANIES";
    UserPermissions["DETAIL_COMPANY"] = "DETAIL_COMPANY";
    UserPermissions["CREATE_COMPANY"] = "CREATE_COMPANY";
    UserPermissions["UPDATE_COMPANY"] = "UPDATE_COMPANY";
    UserPermissions["DELETE_COMPANY"] = "DELETE_COMPANY";
    UserPermissions["CREATE_NOTIFICATION"] = "CREATE_NOTIFICATION";
    UserPermissions["UPDATE_NOTIFICATION"] = "UPDATE_NOTIFICATION";
    UserPermissions["DELETE_NOTIFICATION"] = "DELETE_NOTIFICATION";
    UserPermissions["GET_ALL_PERMISSIONS"] = "GET_ALL_PERMISSIONS";
    UserPermissions["DETAIL_PERMISSION"] = "DETAIL_PERMISSION";
    UserPermissions["CREATE_PERMISSION"] = "CREATE_PERMISSION";
    UserPermissions["UPDATE_PERMISSION"] = "UPDATE_PERMISSION";
    UserPermissions["DELETE_PERMISSION"] = "DELETE_PERMISSION";
    UserPermissions["GET_ALL_PARTNERS"] = "GET_ALL_PARTNERS";
    UserPermissions["DETAIL_PARTNER"] = "DETAIL_PARTNER";
    UserPermissions["CREATE_PARTNER"] = "CREATE_PARTNER";
    UserPermissions["UPDATE_PARTNER"] = "UPDATE_PARTNER";
    UserPermissions["DELETE_PARTNER"] = "DELETE_PARTNER";
    UserPermissions["GET_ALL_PRODUCTS"] = "GET_ALL_PRODUCTS";
    UserPermissions["DETAIL_PRODUCT"] = "DETAIL_PRODUCT";
    UserPermissions["CREATE_PRODUCT"] = "CREATE_PRODUCT";
    UserPermissions["UPDATE_PRODUCT"] = "UPDATE_PRODUCT";
    UserPermissions["DELETE_PRODUCT"] = "DELETE_PRODUCT";
    UserPermissions["GET_PRODUCT_COST"] = "GET_PRODUCT_COST";
    UserPermissions["GET_ALL_STOCK"] = "GET_ALL_STOCK";
    UserPermissions["GET_ALL_PROFILES"] = "GET_ALL_PROFILES";
    UserPermissions["DETAIL_PROFILE"] = "DETAIL_PROFILE";
    UserPermissions["CREATE_PROFILE"] = "CREATE_PROFILE";
    UserPermissions["UPDATE_PROFILE"] = "UPDATE_PROFILE";
    UserPermissions["DELETE_PROFILE"] = "DELETE_PROFILE";
    UserPermissions["GET_ALL_SAVED_FILES"] = "GET_ALL_SAVED_FILES";
    UserPermissions["DETAIL_SAVED_FILE"] = "DETAIL_SAVED_FILE";
    UserPermissions["CREATE_SAVED_FILE"] = "CREATE_SAVED_FILE";
    UserPermissions["UPDATE_SAVED_FILE"] = "UPDATE_SAVED_FILE";
    UserPermissions["DELETE_SAVED_FILE"] = "DELETE_SAVED_FILE";
    UserPermissions["GET_ALL_FINANCIAL_TRANSACTIONS"] = "GET_ALL_FINANCIAL_TRANSACTIONS";
    UserPermissions["DETAIL_FINANCIAL_TRANSACTION"] = "DETAIL_FINANCIAL_TRANSACTION";
    UserPermissions["CREATE_FINANCIAL_TRANSACTION"] = "CREATE_FINANCIAL_TRANSACTION";
    UserPermissions["UPDATE_FINANCIAL_TRANSACTION"] = "UPDATE_FINANCIAL_TRANSACTION";
    UserPermissions["DELETE_FINANCIAL_TRANSACTION"] = "DELETE_FINANCIAL_TRANSACTION";
    UserPermissions["GET_ALL_INVENTORY_TRANSACTIONS"] = "GET_ALL_INVENTORY_TRANSACTIONS";
    UserPermissions["DETAIL_INVENTORY_TRANSACTION"] = "DETAIL_INVENTORY_TRANSACTION";
    UserPermissions["CREATE_INVENTORY_TRANSACTION"] = "CREATE_INVENTORY_TRANSACTION";
    UserPermissions["UPDATE_INVENTORY_TRANSACTION"] = "UPDATE_INVENTORY_TRANSACTION";
    UserPermissions["DELETE_INVENTORY_TRANSACTION"] = "DELETE_INVENTORY_TRANSACTION";
    UserPermissions["GET_ALL_SALES"] = "GET_ALL_SALES";
    UserPermissions["DETAIL_SALE"] = "DETAIL_SALE";
    UserPermissions["CREATE_SALE"] = "CREATE_SALE";
    UserPermissions["UPDATE_SALE"] = "UPDATE_SALE";
    UserPermissions["DELETE_SALE"] = "DELETE_SALE";
    UserPermissions["CAN_NEXT_SALE_STEP"] = "CAN_NEXT_SALE_STEP";
    UserPermissions["CAN_BACK_SALE_STEP"] = "CAN_BACK_SALE_STEP";
    UserPermissions["GET_ALL_SUPPLIES"] = "GET_ALL_SUPPLIES";
    UserPermissions["DETAIL_SUPPLY"] = "DETAIL_SUPPLY";
    UserPermissions["CREATE_SUPPLY"] = "CREATE_SUPPLY";
    UserPermissions["UPDATE_SUPPLY"] = "UPDATE_SUPPLY";
    UserPermissions["DELETE_SUPPLY"] = "DELETE_SUPPLY";
    UserPermissions["GET_ALL_USERS"] = "GET_ALL_USERS";
    UserPermissions["DETAIL_USER"] = "DETAIL_USER";
    UserPermissions["CREATE_USER"] = "CREATE_USER";
    UserPermissions["UPDATE_USER"] = "UPDATE_USER";
    UserPermissions["DELETE_USER"] = "DELETE_USER";
    UserPermissions["GET_COMPANY_DASHBOARD_DATA"] = "GET_COMPANY_DASHBOARD_DATA";
    UserPermissions["SAVE_NFCE_DATA"] = "SAVE_NFCE_DATA";
    UserPermissions["GET_ALL_NFCE"] = "GET_ALL_NFCE";
    UserPermissions["DETAIL_NFCE"] = "DETAIL_NFCE";
})(UserPermissions || (exports.UserPermissions = UserPermissions = {}));
// TODO: colocar nomes
exports.UserPermissionsLabels = {
    GET_ALL_COMPANIES: null,
    DETAIL_COMPANY: null,
    CREATE_COMPANY: null,
    UPDATE_COMPANY: null,
    DELETE_COMPANY: null,
    CREATE_NOTIFICATION: null,
    UPDATE_NOTIFICATION: null,
    DELETE_NOTIFICATION: null,
    GET_ALL_PERMISSIONS: null,
    DETAIL_PERMISSION: null,
    CREATE_PERMISSION: null,
    UPDATE_PERMISSION: null,
    DELETE_PERMISSION: null,
    GET_ALL_PARTNERS: null,
    DETAIL_PARTNER: null,
    CREATE_PARTNER: 'criar:cliente',
    UPDATE_PARTNER: null,
    DELETE_PARTNER: null,
    GET_ALL_PRODUCTS: null,
    DETAIL_PRODUCT: null,
    CREATE_PRODUCT: null,
    UPDATE_PRODUCT: null,
    DELETE_PRODUCT: null,
    GET_PRODUCT_COST: null,
    GET_ALL_STOCK: null,
    GET_ALL_PROFILES: null,
    DETAIL_PROFILE: null,
    CREATE_PROFILE: null,
    UPDATE_PROFILE: null,
    DELETE_PROFILE: null,
    GET_ALL_SAVED_FILES: null,
    DETAIL_SAVED_FILE: null,
    CREATE_SAVED_FILE: null,
    UPDATE_SAVED_FILE: null,
    DELETE_SAVED_FILE: null,
    GET_ALL_FINANCIAL_TRANSACTIONS: null,
    DETAIL_FINANCIAL_TRANSACTION: null,
    CREATE_FINANCIAL_TRANSACTION: null,
    UPDATE_FINANCIAL_TRANSACTION: null,
    DELETE_FINANCIAL_TRANSACTION: null,
    GET_ALL_INVENTORY_TRANSACTIONS: null,
    DETAIL_INVENTORY_TRANSACTION: null,
    CREATE_INVENTORY_TRANSACTION: null,
    UPDATE_INVENTORY_TRANSACTION: null,
    DELETE_INVENTORY_TRANSACTION: null,
    GET_ALL_SALES: null,
    DETAIL_SALE: null,
    CREATE_SALE: null,
    UPDATE_SALE: null,
    DELETE_SALE: null,
    CAN_NEXT_SALE_STEP: null,
    CAN_BACK_SALE_STEP: null,
    GET_ALL_SUPPLIES: null,
    DETAIL_SUPPLY: null,
    CREATE_SUPPLY: null,
    UPDATE_SUPPLY: null,
    DELETE_SUPPLY: null,
    GET_ALL_USERS: null,
    DETAIL_USER: null,
    CREATE_USER: null,
    UPDATE_USER: null,
    DELETE_USER: null,
    GET_COMPANY_DASHBOARD_DATA: null,
    SAVE_NFCE_DATA: null,
    GET_ALL_NFCE: null,
    DETAIL_NFCE: null,
};
