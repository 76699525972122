import { onCLS, onFCP, onFID, onLCP, onTTFB, onINP } from 'web-vitals';

const reportWebVitals = () => {
  onCLS(console.log);
  onFID(console.log);
  onFCP(console.log);
  onLCP(console.log);
  onTTFB(console.log);
  onINP(console.log);
};

export default reportWebVitals;
