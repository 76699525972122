"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nfceKeyValidator = void 0;
const yup_custom_1 = require("../yup-custom");
// TODO:
const nfceKeySchema = yup_custom_1.yup.string().required() /* .matches(/\d{3}\|\d{3}/) */;
const nfceKeyValidator = async (body) => {
    return await nfceKeySchema.validate(body, { stripUnknown: true });
};
exports.nfceKeyValidator = nfceKeyValidator;
