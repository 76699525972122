"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityLogType = void 0;
var ActivityLogType;
(function (ActivityLogType) {
    ActivityLogType["INSERT"] = "INSERT";
    ActivityLogType["UPDATE"] = "UPDATE";
    ActivityLogType["SOFT_DELETE"] = "SOFT_DELETE";
    ActivityLogType["HARD_DELETE"] = "HARD_DELETE";
    ActivityLogType["RECOVER"] = "RECOVER";
})(ActivityLogType || (exports.ActivityLogType = ActivityLogType = {}));
