import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider } from 'context/AuthContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import(`moment/locale/${navigator.language.toLocaleLowerCase()}`).then();
import 'moment/locale/pt-br';
import ErrorCatcher from 'components/error/ErrorCatcher';

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        {/* <ThemeProvider theme={{...themes(customization), palette: {mode: 'dark'}}}> */}
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CssBaseline />
          <NavigationScroll>
            <AuthProvider>
              <ToastContainer /* theme="dark" */ />
              <ErrorCatcher>
                <Routes />
              </ErrorCatcher>
            </AuthProvider>
          </NavigationScroll>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
