"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProductValidator = exports.getProductSchema = exports.updateProductValidator = exports.updateProductSchema = exports.createProductValidator = exports.createProductSchema = void 0;
const Product_1 = require("../models/Product");
const yup_custom_1 = require("../yup-custom");
const validatorBase = yup_custom_1.yup.object().shape({
    name: yup_custom_1.yup.string().required(),
    type: yup_custom_1.yup.string().enum(Product_1.ProductType).required(),
    minQuantity: yup_custom_1.yup.number(),
    salePrice: yup_custom_1.yup.number().when('type', {
        is: Product_1.ProductType.PRODUCED,
        then: (yup) => yup.required(),
        otherwise: (yup) => yup.strip(),
    }),
    recipeType: yup_custom_1.yup.mixed().enum(Product_1.RecipeType).required(),
    efficiency: yup_custom_1.yup.number().when('recipeType', {
        is: Product_1.RecipeType.COMPOSED,
        then: (yup) => yup.required(),
        otherwise: (yup) => yup.strip(),
    }),
    measurementUnit: yup_custom_1.yup.mixed().enum(Product_1.MeasurementUnits).required(),
    observations: yup_custom_1.yup.string().nullable(),
    recipeIngredients: yup_custom_1.yup
        .array()
        .of(yup_custom_1.yup.object().shape({
        id: yup_custom_1.yup.number().required(),
        size: yup_custom_1.yup.number().required(),
    }))
        .when('recipeType', {
        is: Product_1.RecipeType.COMPOSED,
        then: (yup) => yup.required(),
        otherwise: (yup) => yup.strip(),
    }),
});
exports.createProductSchema = validatorBase.concat(yup_custom_1.yup.object().shape({}));
const createProductValidator = async (body) => {
    return await exports.createProductSchema.validate(body, { stripUnknown: true });
};
exports.createProductValidator = createProductValidator;
exports.updateProductSchema = validatorBase.concat(yup_custom_1.yup.object().shape({
    id: yup_custom_1.yup.number().required(),
}));
const updateProductValidator = async (body) => {
    return await exports.updateProductSchema.validate(body, { stripUnknown: true });
};
exports.updateProductValidator = updateProductValidator;
exports.getProductSchema = yup_custom_1.yup
    .object()
    .shape({
    name: yup_custom_1.yup.string(),
    type: yup_custom_1.yup.string().enum(Product_1.ProductType),
    recipeType: yup_custom_1.yup.string().enum(Product_1.RecipeType),
});
const getProductValidator = async (body) => {
    return await exports.getProductSchema.validate(body, { stripUnknown: true });
};
exports.getProductValidator = getProductValidator;
