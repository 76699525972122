"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPartnerValidator = exports.getPartnerSchema = void 0;
const yup_custom_1 = require("../yup-custom");
exports.getPartnerSchema = yup_custom_1.yup
    .object()
    .shape({
    name: yup_custom_1.yup.string(),
});
const getPartnerValidator = async (body) => {
    return await exports.getPartnerSchema.validate(body, { stripUnknown: true });
};
exports.getPartnerValidator = getPartnerValidator;
