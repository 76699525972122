import { ApiResponse, ChangeMyPasswordDTO } from '@mrp-system/common';
import api from 'config/api';
import { APIError } from 'error/APIError';

export const changeMyPassword = async (formData: ChangeMyPasswordDTO) => {
  const { data, headers } = await api.post<ApiResponse<null>>(
    '/user/change-my-password',
    formData
  );

  if (data.status === 'SUCCESS' || data.status === 'WARNING') {
    return data;
  } else {
    throw new APIError(data.message, data, headers);
  }
};
