// assets
import { UserPermissions } from '@mrp-system/common';
import {
  IconKey,
  IconTypography,
  IconForklift,
  IconToolsKitchen,
  IconBusinessplan,
  IconBuildingWarehouse,
  IconCake,
} from '@tabler/icons';
import { SidebarMenu } from 'utils/types';

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages: SidebarMenu = {
  id: 'manager',
  title: 'Gerenciamento',
  type: 'group',
  children: [
    {
      id: 'produtos',
      title: 'Produtos',
      type: 'item',
      url: '/produtos',
      icon: IconCake,
      breadcrumbs: true,
      permissionsRequired: [UserPermissions.GET_ALL_PRODUCTS],
    },
    {
      id: 'estoque',
      title: 'Estoque',
      type: 'item',
      url: '/estoque',
      icon: IconBuildingWarehouse,
      breadcrumbs: false,
      permissionsRequired: [UserPermissions.GET_ALL_STOCK],
    },
    {
      id: 'movimentacao-estoque',
      title: 'Mov. de Estoque',
      type: 'item',
      url: '/movimentacao-estoque',
      icon: IconForklift,
      breadcrumbs: false,
      permissionsRequired: [UserPermissions.GET_ALL_INVENTORY_TRANSACTIONS],
    },
    {
      id: 'financeiro',
      title: 'Financeiro',
      type: 'item',
      url: '/financeiro',
      icon: IconBusinessplan,
      breadcrumbs: false,
      permissionsRequired: [UserPermissions.GET_ALL_FINANCIAL_TRANSACTIONS],
    },
    {
      id: 'inventário',
      title: 'Inventário',
      type: 'item',
      url: '/inventário',
      icon: IconToolsKitchen,
      breadcrumbs: false,
      permissionsRequired: null,
    },
  ],
};

export default pages;
