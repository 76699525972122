import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useTheme,
} from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import FormControlPassword from './FormControlPassword';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ChangeMyPasswordDTO,
  changeMyPasswordSchema,
  yup,
} from '@mrp-system/common';
import { Check, Close } from '@mui/icons-material';
import { defaultErrorHandler } from 'error/defaultErrorHandler';
import { toastSuccess } from 'utils/toast-utils';
import { changeMyPassword } from 'services/userService';
import LabelLoading from './LabelLoading';

type ResetPasswordDialogProps = {
  onClose: () => void;
  isShow: boolean;
};

type ResetPasswordFormData = ChangeMyPasswordDTO & {
  confirmNewPassword: string;
};

const ResetPasswordDialog: FunctionComponent<ResetPasswordDialogProps> = ({
  isShow,
  onClose,
}) => {
  const theme = useTheme();
  const [isSending, setIsSending] = useState(false);

  const schema: yup.SchemaOf<ResetPasswordFormData> =
    changeMyPasswordSchema.concat(
      yup.object().shape({
        confirmNewPassword: yup
          .string()
          .required()
          .oneOf([yup.ref('newPassword')], 'As senhas devem coincidir'),
      })
    );

  const {
    register,
    watch,
    trigger,
    handleSubmit: submitter,
    formState: { isValidating, errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isValidating) trigger('confirmNewPassword');
  }, [watch('newPassword')]);

  const handleSubmit = async (formData: ResetPasswordFormData) => {
    try {
      setIsSending(true);

      await changeMyPassword(formData);

      toastSuccess('A senha foi alterada');
      onClose();
    } catch (error) {
      defaultErrorHandler(error);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Dialog onClose={onClose} open={isShow}>
      <DialogTitle variant='h2'>Alterar senha</DialogTitle>
      <DialogContent>
        <form
          id='ResetPasswordDialogFormId'
          onSubmit={submitter(handleSubmit as any, console.log)}
        >
          <Grid container spacing={3} paddingTop={1}>
            <Grid item xs={12}>
              <FormControlPassword
                label='Senha atual'
                name='currentPassword'
                register={register}
                errors={errors}
                disabled={isSending}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlPassword
                label='Nova senha'
                name='newPassword'
                register={register}
                errors={errors}
                disabled={isSending}
              />
              {[
                {
                  condition: watch('newPassword', '').length >= 8,
                  message: 'No mínimo 8 caracteres',
                },
              ].map(({ condition, message }) => (
                <Box
                  key={message}
                  display='flex'
                  alignItems='center'
                  color={
                    condition
                      ? theme.palette.grey[400]
                      : theme.palette.error.dark
                  }
                >
                  {condition ? (
                    <Check fontSize='small' />
                  ) : (
                    <Close fontSize='small' />
                  )}{' '}
                  {message}
                </Box>
              ))}
            </Grid>
            <Grid item xs={12}>
              <FormControlPassword
                label='Confirmar nova senha'
                name='confirmNewPassword'
                register={register}
                errors={errors}
                disabled={isSending}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button autoFocus color='primary' variant='contained' onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color='error'
          variant='text'
          type='submit'
          form='ResetPasswordDialogFormId'
          disabled={isSending}
        >
          {isSending ? <LabelLoading /> : 'Confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordDialog;
