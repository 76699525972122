"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecipeType = exports.ProductType = exports.MeasurementUnits = void 0;
var MeasurementUnits;
(function (MeasurementUnits) {
    MeasurementUnits["MILLILITER"] = "MILLILITER";
    MeasurementUnits["GRAM"] = "GRAM";
    MeasurementUnits["UNIT"] = "UNIT";
})(MeasurementUnits || (exports.MeasurementUnits = MeasurementUnits = {}));
var ProductType;
(function (ProductType) {
    ProductType["PRODUCED"] = "PRODUCED";
    ProductType["INPUT"] = "INPUT";
})(ProductType || (exports.ProductType = ProductType = {}));
var RecipeType;
(function (RecipeType) {
    RecipeType["SIMPLE"] = "SIMPLE";
    RecipeType["COMPOSED"] = "COMPOSED";
})(RecipeType || (exports.RecipeType = RecipeType = {}));
