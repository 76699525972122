import {
  createContext,
  FunctionComponent,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { jwtDecode } from 'jwt-decode';
import { loginUser } from 'services/authenticationService';
import { Token, UserType } from '@mrp-system/common';
import { APIError } from 'error/APIError';
import moment from 'moment';
import { AxiosError } from 'axios';

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthState {
  user: Token;
}

interface SignIn {
  message: string;
}

export interface SignInSuccess extends SignIn {
  status: 'SUCCESS';
  type: UserType;
}

export interface SignInWarning extends SignIn {
  status: 'WARNING';
  type: null;
}

export interface SignInFail extends SignIn {
  status: 'FAIL';
  type: null;
}

interface AuthContextState {
  user: Token;
  signIn(
    credentials: SignInCredentials
  ): Promise<SignInSuccess | SignInWarning | SignInFail>;
  signOut(): void;
  isAuthenticated(): boolean;
}

const AuthContext = createContext<AuthContextState>({} as AuthContextState);

const AuthProvider: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  const [data, setData] = useState<AuthState>(() => {
    const user = localStorage.getItem('@mrp-system:user');

    if (user) {
      return {
        user: JSON.parse(user),
      };
    }

    return {} as AuthState;
  });

  const signIn = useCallback<
    (
      credentials: SignInCredentials
    ) => Promise<SignInSuccess | SignInWarning | SignInFail>
  >(async (credentials) => {
    try {
      const { status, payload, message } = await loginUser(credentials);

      if (status === 'WARNING') {
        return {
          status: 'WARNING',
          type: null,
          message: message,
        };
      } else {
        localStorage.setItem('@mrp-system:user', JSON.stringify(payload.user));
        localStorage.setItem('@mrp-system:token', payload.token);

        setData({ user: payload.user });
        return {
          status: 'SUCCESS',
          type: payload.user.type,
          message: '',
        };
      }
    } catch (error) {
      if (error instanceof APIError) {
        return {
          status: 'FAIL',
          type: null,
          message: error.message,
        };
      } else if (error instanceof AxiosError && error.response?.data.message) {
        return {
          status: 'FAIL',
          type: null,
          message: error.response.data.message,
        };
      } else {
        return {
          status: 'FAIL',
          type: null,
          message: 'Erro interno. Tente recarregar a página',
        };
      }
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@mrp-system:user');
    localStorage.removeItem('@mrp-system:token');

    setData({} as AuthState);
  }, []);

  const isAuthenticated = useCallback(() => {
    try {
      const token = localStorage.getItem('@mrp-system:token');

      if (token === null) {
        return false;
      } else {
        const tokenDecoded = jwtDecode<Token & { iat: number; exp: number }>(
          token
        );
        const timestamp = moment().valueOf();

        return !!tokenDecoded && tokenDecoded.exp * 1000 >= timestamp
          ? true
          : false;
      }
    } catch (error) {
      return false;
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, isAuthenticated }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
