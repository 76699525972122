"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionStatus = exports.TransactionRelative = exports.TransactionType = void 0;
var TransactionType;
(function (TransactionType) {
    TransactionType["INPUT"] = "INPUT";
    TransactionType["OUTPUT"] = "OUTPUT";
})(TransactionType || (exports.TransactionType = TransactionType = {}));
var TransactionRelative;
(function (TransactionRelative) {
    TransactionRelative["FINANCIAL"] = "FINANCIAL";
    TransactionRelative["INVENTORY"] = "INVENTORY";
    TransactionRelative["SUPPLY"] = "SUPPLY";
    TransactionRelative["SALE"] = "SALE";
})(TransactionRelative || (exports.TransactionRelative = TransactionRelative = {}));
var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["PENDING"] = "PENDING";
    TransactionStatus["CONFIRMED"] = "CONFIRMED";
    TransactionStatus["FINISHED"] = "FINISHED";
})(TransactionStatus || (exports.TransactionStatus = TransactionStatus = {}));
